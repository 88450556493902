import Footer from '@/components/layout/Footer';
import AboutUs from '@/components/main/AboutUs';
import BannerSwiper from '@/components/main/BannerSwiper';
import CategoryList from '@/components/main/CategoryList';
import PopularTop10 from '@/components/main/PopularTop10';
import SideMenu from '@/components/main/SideMenu';
import ThisWeekRecommandation from '@/components/main/ThisWeekRecommandation';
import TodayGroundStory from '@/components/main/TodayGroundStory';
import { Banner } from '@/hooks/main/Banner.hooks';
import { Category } from '@/hooks/main/Main.hooks';
import { RandomItem } from '@/hooks/main/ThisWeekRecommandation.hooks';
import { TodayStory } from '@/hooks/main/TodayGroundStory.hooks';
import { TopItem } from '@/hooks/main/TopItem.hooks';
import axios from 'axios';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import SearchIcon from 'public/assets/images/common/search-icon.svg';
import MenuIcon from 'public/assets/images/layout/main/menu-hamburger-icon.svg';
import NotificationIcon from 'public/assets/images/layout/main/notification-icon.svg';
import UpArrow from 'public/assets/images/main/up-arrow.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import mls from '../styles/layout/layout-main.module.scss';
import styles from '../styles/main/main.module.scss';

type Props = {
  categories: Category[];
  banners: Banner[];
  randomItems: RandomItem[];
  topReserves: TopItem[];
  topReviews: TopItem[];
  todayStories: TodayStory[];
};

export default function Home({
  categories,
  banners,
  randomItems,
  topReserves,
  topReviews,
  todayStories,
}: Props) {
  const router = useRouter();
  const topContentRef = useRef<HTMLAnchorElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (router.query.access_token) {
      window.sessionStorage.setItem('token', router.query.access_token as string);
    }
  }, [router.query.access_token]);

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop } = contentRef.current;
      setScrollPosition(scrollTop);
    }
  };

  const moveToTop = () => {
    topContentRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const SearchInput = () => {
    return (
      <>
        <SearchIcon />
        <div className={styles.searchInputWrapper}>
          <input placeholder="무엇을 찾아볼까요?" readOnly={true} />
        </div>
      </>
    );
  };

  const handleAlarmClick = useCallback(() => {
    toast('추후 업데이트 예정입니다.');
  }, []);

  return (
    <div className={mls.root}>
      <div className={mls.container}>
        <SideMenu isOpen={isMenuOpen} onClose={onMenuClose} categories={categories} />
        <div
          className={classNames(
            mls.header,
            { [mls.headerShadow]: scrollPosition > 0 },
            { [mls.searchHeader]: scrollPosition >= 40 }
          )}>
          <button className={mls.menuBtn} onClick={toggleMenu}>
            <MenuIcon />
          </button>
          <div className={mls.headerCenter}>
            {scrollPosition < 40 ? (
              <img src="/assets/images/layout/main/ground-logo.png" />
            ) : (
              <Link className={styles.headerSearchBox} href="/search">
                <SearchInput />
              </Link>
            )}
          </div>
          <button className={mls.alarmBtn} type="button" onClick={handleAlarmClick}>
            <NotificationIcon />
          </button>
        </div>
        <div
          ref={contentRef}
          className={classNames(mls.content, styles.mainContent)}
          onScroll={handleScroll}>
          <Link ref={topContentRef} className={styles.searchBox} href="/search">
            <SearchInput />
          </Link>
          <CategoryList categories={categories} />
          <BannerSwiper banners={banners} />
          <PopularTop10 topReserves={topReserves} topReviews={topReviews} />
          <TodayGroundStory todayStories={todayStories} />
          <ThisWeekRecommandation randomItems={randomItems} />
          <AboutUs />
        </div>
        <Footer />
        {scrollPosition >= 50 && (
          <button className={styles.scrollToTop} onClick={moveToTop}>
            <UpArrow />
          </button>
        )}
      </div>
    </div>
  );
}

export async function getServerSideProps() {
  const baseUrl = process.env.NEXT_PUBLIC_APP_API;
  const categoriesReq = axios.get<GroundResponse<Category[]>>(`${baseUrl}/app/categories`);
  const bannersReq = axios.get<GroundResponse<Banner[]>>(`${baseUrl}/app/banners`);
  const randomItemsReq = axios.get<GroundResponse<RandomItem[]>>(
    `${baseUrl}/app/products/random-4`
  );
  const topReservesReq = axios.get<GroundResponse<Banner[]>>(
    `${baseUrl}/app/products/reservation/top-10`
  );
  const topReviewsReq = axios.get<GroundResponse<Banner[]>>(
    `${baseUrl}/app/products/review/top-10`
  );
  const todayStoryReq = axios.get<GroundResponse<Banner[]>>(`${baseUrl}/app/stories/today`);

  const [categories, banners, randomItems, topReserves, topReviews, todayStories] =
    await Promise.all([
      categoriesReq,
      bannersReq,
      randomItemsReq,
      topReservesReq,
      topReviewsReq,
      todayStoryReq,
    ]);

  return {
    props: {
      categories: categories.data.result,
      banners: banners.data.result,
      randomItems: randomItems.data.result,
      topReserves: topReserves.data.result,
      topReviews: topReviews.data.result,
      todayStories: todayStories.data.result,
    },
  };
}
