import { TopItem } from '@/hooks/main/TopItem.hooks';
import { formatCurrency } from '@/utils/number';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styles from '../../../styles/main/popular-top-10.module.scss';
import FloatingBadgeList from './FloatingBadgeList';

const optionList = ['#가장 많이 구매했어요', '#가장 후기가 많아요'];

type Props = {
  topReserves: TopItem[];
  topReviews: TopItem[];
};

export default function PopularTop10({ topReserves, topReviews }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const updateSelectedIndex = (index: number) => {
    setSelectedIndex(index);
  };
  const [itemList, setItemList] = useState<TopItem[]>([]);

  useEffect(() => {
    if (selectedIndex === 0) setItemList(topReserves);
    else setItemList(topReviews);
  }, [selectedIndex]);

  return (
    <div className={styles.root}>
      <div className={styles.title}>인기 TOP 10</div>
      <FloatingBadgeList
        list={optionList.map((item) => ({ label: item, value: item }))}
        setSelectedIndex={updateSelectedIndex}
      />
      <div className={styles.itemSlideContainer}>
        {itemList.map((topItem, index) => (
          <div key={topItem.id} className={styles.itemSlide}>
            <Link
              href={{
                pathname: `/product/${topItem.id}`,
                query: {
                  categoryId: topItem.categoryId,
                  villageName: topItem.villageName,
                },
              }}>
              <div className={styles.itemCard}>
                <div className={styles.itemImgWrapper}>
                  <img src={topItem.thumbnailUrl} />
                  <div className={styles.itemNumber}>{index + 1}</div>
                </div>
                <div className={styles.itemContent}>
                  <div className={styles.itemSubTitle}>
                    {topItem.categoryName} • {topItem.location}
                  </div>
                  <div className={styles.itemMainTitle}>{topItem.name}</div>
                  <div className={styles.itemPrice}>
                    {formatCurrency(topItem.sellingPrice)}원
                    {topItem.categoryName === '숙소/캠핑' ? '~' : ''}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
