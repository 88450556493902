import { TodayStory } from '@/hooks/main/TodayGroundStory.hooks';
import { formatCurrency } from '@/utils/number';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import styles from '../../../styles/main/today-ground-story.module.scss';

type Props = {
  todayStories: TodayStory[];
};

export default function TodayGroundStory({ todayStories }: Props) {
  const handleItemClick = useCallback(() => {
    toast('추후 업데이트 예정입니다.');
  }, []);
  return (
    <div className={styles.root}>
      <div className={styles.title}>오늘의 그라운드 스토리</div>
      <div className={styles.itemSlideContainer}>
        {todayStories.map((story) => (
          <div key={story.id} className={styles.itemSlide} onClick={handleItemClick}>
            <img className={styles.itemImg} src={story.representativeImageUrl} />
            <div className={styles.itemSubTitle}>
              {story.startDate.replaceAll('-', '.')} • 조회수 {formatCurrency(story.viewCount)}
            </div>
            <div className={styles.itemMainTitle}>{story.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
